









import Vue from 'vue';
import { init } from 'echarts';
import axios from 'axios';
export default Vue.extend({
  data(){
    return{
      allChildrenCount:0,
      portalRecommendTreeModel: [],
      inviteeMobile:"",
      token:""
    }
  },
  mounted(){
    this.inviteeMobile=this.$route.query.inviteeMobile??''
    this.token=this.$route.query.token??''
    this.relationshipView()
  },
  methods:{
    relationshipView() {
      let url=`/api/portal-resources/1/portalRecommend/relationshipTree/${this.inviteeMobile}`
      let headers={
        Authorization : `Bearer ${this.token}`,
        PRODUCT_CODE: 'SUPER_ADMIN',
        AGENCY_CODE: 'SUPER_ADMIN',
        APPLICATION_NAME: 'MANAGE',
      }
      axios({
        method: 'get',
        url,
        data:{},
        headers:headers
      })
      .then(res => {
        const result = res.data.result;
        if (res.status === 200) {
          this.allChildrenCount = result.allChildrenCount;
          this.portalRecommendTreeModel = result.portalRecommendTreeModel;
          this.echartsInit();
        }
      })
    },
    echartsInit() {
      const myChart = init(this.$refs.relationshipRef as HTMLElement);
      myChart.showLoading();
      let portalRecommendTreeModel = JSON.parse(JSON.stringify(this.portalRecommendTreeModel));
      myChart.hideLoading();
      if(portalRecommendTreeModel.children){
        // 二级遍历
        portalRecommendTreeModel.children.forEach(function (datum, index) {
          if(datum.children){
            // 三级遍历
            datum.children.forEach(function (datum2, index2) {
              if(datum2.children){
                datum2.collapsed = true
              }
            });
          }
        });
      }
      let jsonObj = {
        name: '我是姓名呢 (12355)\n1845874584',
        children: [
          {
            name: 'cluster',
            children: [
              { name: '我是姓名呢 (12355)\n1845874584', value: 3938 },
              { name: '啦啦啦 (123) \n 1845874584', value: 3812 },
              { name: '啦啦啦 (123) \n 1845874584', value: 6714 },
              { name: '啦啦啦 (123) \n 1845874584', value: 743 },
            ],
          },
        ],
      };
      myChart.setOption(
        {
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
          },
          series: [
            {
              type: 'tree',
              data: [portalRecommendTreeModel],
              top: '30px',
              left: '150px',
              bottom: '30px',
              right: '150px',
              symbolSize: 10,
              label: {
                position: 'left',
                verticalAlign: 'middle',
                align: 'right',
                fontSize: 18,
              },
              leaves: {
                label: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left',
                },
              },
              emphasis: {
                focus: 'descendant',
              },
              expandAndCollapse: true,
              animationDuration: 550,
              animationDurationUpdate: 750,
              roam: true,
            },
          ],
        },true
      );
    },
  }
})
